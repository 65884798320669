const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("BFCAdmin")
    ? JSON.parse(window.localStorage.getItem("BFCAdmin"))
    : undefined

const setUser = user => {
  if (user.token) {
    window.localStorage.setItem("BFCAdmin", JSON.stringify(user))
  } else {
    window.localStorage.removeItem("BFCAdmin")
  }
  return true
}

export const handleSignin = token => {
  if (token) {
    return setUser({
      uname: `admin`,
      token,
    })
  }
  return false
}

export const isSignedIn = () => {
  const user = getUser()
  return user ? !!user.token : false
}

export const authSignOut = callback => {
  setUser({})
  callback()
}
