import styled from "styled-components"

export const Container = styled.div`
  margin-top: 25px;

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & div > span {
    padding-right: 5px;
  }
`

export const Element = styled.button.attrs(props => ({
  type: props.type,
}))`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  background-color: #f1f1f1;
  padding: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: none;

  &:hover {
    color: #fff;
    background: #696969;
    border-color: #696969;
  }
`
