import React from "react"
import { isFieldError } from "../../../utils/helpers"
import { Container, Element } from "./styled"

const Input = ({ type, label, name, value, update, errors, note = "" }) => {
  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <Element
        type={type}
        id={name}
        name={name}
        defaultValue={value}
        autoComplete="off"
        onChange={e => update(name, e.target.value)}
        className={isFieldError(errors, name) ? "error" : ""}
      />
      {note && <small>{note}</small>}
    </Container>
  )
}

export default Input
