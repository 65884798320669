import React, { Fragment } from "react"
import { Link } from "gatsby"

import { Container, NavDiv, H3 } from "./styled"

//import logo from "../../img/honda-classic-cares-logo.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-light-svg-icons"

const Nav = ({ sections, title }) => {
  return (
    <Fragment>
      <Container>
        <img
          src="https://theclassicinthepalmbeaches.com/wp-content/uploads/sites/3/2023/07/the-classic-logo.png"
          alt="HCC Logo"
        />
        <NavDiv>
          {sections.map((item, index) => {
            return index % 2 !== 0 ? (
              <FontAwesomeIcon key={index} icon={faChevronRight} size="xs" />
            ) : !item.to ? (
              <span key={index}>{item.name}</span>
            ) : (
              <Link key={index} to={item.to}>
                {item.name}
              </Link>
            )
          })}
        </NavDiv>
      </Container>
      <H3>{title}</H3>
    </Fragment>
  )
}

export default Nav
