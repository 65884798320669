import React from "react"
import Helmet from "react-helmet"
import icon from "../../img/favicon.png"

const Index = () => {
  return (
    <Helmet>
      <title>BFC Charity & Donation Administration</title>
      <meta
        name="description"
        content="Manage charity and donation details for The Honda Classic Birdies for Children program."
      />
      <meta name="robots" content="noindex" />
      <link rel="shortcut icon" href={icon} />
    </Helmet>
  )
}

export default Index
