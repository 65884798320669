import styled from "styled-components"

export const Container = styled.div`
  border: 1px solid #ddd;
  padding: 7.5px 15px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & > img {
    display: block;
    width: 150px;
    padding-right: 12.5px;

    @media only screen and (max-width: 767px) {
      padding-right: 0;
    }
  }
`

export const NavDiv = styled.nav`
  display: flex;
  align-items: center;
  border-left: 1px solid #ddd;
  padding-left: 12.5px;

  @media only screen and (max-width: 767px) {
    border-left: none;
    padding-left: 0;
  }

  & > svg {
    color: var(--darkgrey);
  }

  & > *:first-child {
    padding-right: 7.5px;
  }

  & > *:nth-child(3) {
    padding-right: 7.5px;
  }

  & > *:nth-child(3) {
    padding-left: 7.5px;
  }

  & > *:nth-child(5) {
    padding-left: 7.5px;
  }
`

export const H3 = styled.h3`
  margin-top: 15px;
  margin-bottom: 0;
  padding-bottom: 0;
`
