import styled from "styled-components"

export const Container = styled.div`
  padding: 25px;
  background-color: #fff;
`

export const Grid = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1.75fr;
  grid-gap: 25px;
  justify-items: center;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`
export const Grid2 = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 35px;
  justify-items: center;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  & > div {
    width: 100%;

    & > h3 {
      font-size: 1.15rem;
      margin-bottom: 5px;
    }

    & > table th {
      width: 35%;
    }

    & > table td.logo {
      padding: 25px;
    }

    & > table td.logo img {
      margin: 0 auto;
      display: block;
      width: 175px;
      height: auto;
    }
  }
`
