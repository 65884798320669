import styled from "styled-components"

export const Container = styled.div`
  margin: 25px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.9rem;

  &.working {
    color: var(--working);
  }

  &.success {
    color: var(--success);
  }

  &.error {
    color: var(--error);
  }

  & > span {
    padding-top: 5px;
  }
`
