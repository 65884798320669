import React from "react"
import { Container, Element } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Submit = ({ label }) => {
  return (
    <Container>
      <Element type={`submit`}>
        <div>
          <span>{label}</span>
          <FontAwesomeIcon icon={faChevronCircleRight} size="lg" />
        </div>
      </Element>
    </Container>
  )
}

export default Submit
